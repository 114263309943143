<template>
  <q-dialog v-model="visible" full-height position="right">
    <q-card style="width: 1000px; max-width: 90vw">
      <q-toolbar>
        <q-toolbar-title>
          {{ modalTitle }}
        </q-toolbar-title>
        <q-btn flat round dense icon="close" @click="onCancel" />
      </q-toolbar>
      <q-card-section>
        <q-form ref="formRef" greedy>
          <div class="max-height-dialog">
            <label class="text-grey-9 text-bold">{{ $t('label.traffic_source.liff_id') }}</label>
            <q-input lazy-rules outlined v-model="form.liff_id" dense :placeholder="$t('label.traffic_source.liff_id')">
            </q-input>
            <div class="q-mt-sm">
              <label class="text-grey-9 text-bold">{{ $t('label.app_url') }}</label>
            </div>
            <div class="q-mt-sm">
              <q-badge outline align="middle" color="primary">
                <TagContent :tagValue="getWebhookURL(form._id)"></TagContent>
              </q-badge>
            </div>
          </div>
          <q-space class="q-my-md" />
          <div class="row no-wrap items-center">
            <q-space />
            <div class="q-gutter-sm">
              <q-btn no-caps flat color="white" text-color="black" @click="onCancel">{{ $t('cancel') }}</q-btn>
              <q-btn no-caps color="primary" @click="onSubmit"> {{ $t('save') }} </q-btn>
            </div>
          </div>
        </q-form>
      </q-card-section>
    </q-card>
  </q-dialog>
</template>

<script lang="ts">
import { Options, mixins } from 'vue-class-component'
import { Prop } from 'vue-property-decorator'
import { maska } from 'maska'
import cloneDeep from 'lodash/cloneDeep'
import { IApp } from '@/utils/types'
import { ACTION_APP } from '@/store/actions'
import CLoading from '@/components/common/ui/CLoading.vue'
import BaseFormDialogMixin from '../common/mixins/BaseFormDialogMixin.vue'
import TagContent from '@/components/common/ui/TagContent.vue'

@Options({
  components: { CLoading, TagContent },
  directives: { maska },
  emits: ['update:onSelect'],
})
export default class LIFFSettingFormModal extends mixins(BaseFormDialogMixin) {
  @Prop()
  data!: IApp

  form: IApp = {}

  get requiredRule() {
    const requiredRule = {
      liff_id: this.$t('validate.this_field_is_required', {
        placeholder: this.$t('label.traffic_source.liff_id'),
      }),
    }
    return requiredRule
  }

  get modalTitle() {
    return this.$t('label.traffic_source.liff_setting')
  }

  onSubmit() {
    this.$refs.formRef
      .validate()
      .then(async (success: boolean) => {
        if (!success) {
          return
        }

        await this.doSave()
      })
      .catch((error: unknown) => {
        console.log('error', error)
      })
  }

  async doSave() {
    const { dispatch } = this.$store
    let success = false
    success = await dispatch(ACTION_APP.UPDATE, {
      _id: this.form._id,
      ...this.form,
    })

    if (success) {
      this.$q.notify({
        message: this.$t('messages.saved'),
        color: 'positive',
      })
    }

    this.visible = false
  }

  getWebhookURL(app_id) {
    const baseUrl = process.env.VUE_APP_API_ENDPOINT
    const url = `${baseUrl}webhook/liff-app?app_id=${app_id}`
    return url
  }

  created() {
    this.form = { ...this.form, ...cloneDeep(this.data) }
  }
}
</script>
