<template>
  <q-dialog v-model="visible" full-height position="right">
    <q-card style="width: 1000px; max-width: 90vw">
      <q-toolbar>
        <q-toolbar-title>
          {{ modalTitle }}
        </q-toolbar-title>
        <q-btn flat round dense icon="close" @click="onCancel" />
      </q-toolbar>
      <q-card-section>
        <q-form ref="formRef" greedy>
          <q-stepper v-model="step" header-nav ref="stepper" color="primary" animated>
            <q-step
              :name="1"
              :title="$t('label.traffic_source.how_to_step1_title')"
              icon="settings"
              :done="step > 1"
              :header-nav="step > 1"
            >
              <div class="text-grey-6">
                <p>{{ $t('label.traffic_source.endpoint_liff') }}</p>
                <q-badge outline align="middle" color="primary">
                  <TagContent :tagValue="getWebhookURL(form._id)"></TagContent>
                </q-badge>
              </div>
              <q-stepper-navigation>
                <q-btn
                  @click="
                    () => {
                      done1 = true
                      step = 2
                    }
                  "
                  color="primary"
                  label="Continue"
                />
              </q-stepper-navigation>
            </q-step>

            <q-step
              :name="2"
              :title="$t('label.traffic_source.how_to_step2_title')"
              icon="create_new_folder"
              :done="step > 2"
              :header-nav="step > 2"
            >
              {{ $t('label.traffic_source.how_to_step2_content') }}
              <br />
              <b>LIFF APPの作成方法</b>
              <ol>
                <li>LINE Developerで新規チャンネル作成を選択</li>
                <li>チャンネルタイプを"LINE Login"を選択</li>
                <li>任意のチャンネル情報を入力と作成</li>
                <li>作成したチャンネルのLIFFタブに移動し追加を選択</li>
                <li>以下情報を設定後、作成</li>
                <li>
                  LIFFアプリ名: 任意<br />
                  <ul>
                    <li>サイズ: Full</li>
                    <li>エンドポイントURL: 取得したエンドポイントURLを設定</li>
                    <li>
                      Scope: profile, openId,
                      chat_message.writeを選択。(chat_message.writeは"全て表示"をクリックすると表示されます)
                    </li>
                    <li>ボットリンク機能: ON</li>
                    <li>Scan QR: 有効</li>
                  </ul>
                </li>
              </ol>
              <q-stepper-navigation>
                <q-btn
                  @click="
                    () => {
                      done2 = true
                      step = 3
                    }
                  "
                  color="primary"
                  label="Continue"
                />
                <q-btn flat @click="step = 1" color="primary" label="Back" class="q-ml-sm" />
              </q-stepper-navigation>
            </q-step>

            <q-step
              :name="3"
              :title="$t('label.traffic_source.how_to_step3_title')"
              icon="add_comment"
              :header-nav="step > 3"
            >
              <div class="max-height-dialog">
                <label class="text-grey-9 text-bold">{{ $t('label.traffic_source.liff_id') }}</label>
                <q-input
                  lazy-rules
                  :rules="[(val) => (val && val.length > 0) || requiredRule.liff_id]"
                  outlined
                  v-model="form.liff_id"
                  dense
                  :placeholder="$t('label.traffic_source.liff_id')"
                >
                </q-input>
              </div>

              <q-stepper-navigation>
                <q-btn color="primary" @click="onSubmit()" label="Finish" />
                <q-btn flat @click="step = 2" color="primary" label="Back" class="q-ml-sm" />
              </q-stepper-navigation>
            </q-step>
          </q-stepper>
        </q-form>
      </q-card-section>
    </q-card>
  </q-dialog>
</template>

<script lang="ts">
import { Options, mixins } from 'vue-class-component'
import { Prop } from 'vue-property-decorator'
import { maska } from 'maska'
import { IApp } from '@/utils/types'
import cloneDeep from 'lodash/cloneDeep'
import { ACTION_APP } from '@/store/actions'
import CLoading from '@/components/common/ui/CLoading.vue'
import BaseFormDialogMixin from '../common/mixins/BaseFormDialogMixin.vue'
import TagContent from '@/components/common/ui/TagContent.vue'

@Options({
  components: { CLoading, TagContent },
  directives: { maska },
  emits: ['update:onSelect'],
})
export default class LIFFHowToSetupFormModal extends mixins(BaseFormDialogMixin) {
  @Prop()
  data!: IApp

  form: IApp = {}
  step = 1

  get requiredRule() {
    const requiredRule = {
      liff_id: this.$t('validate.this_field_is_required', {
        placeholder: this.$t('label.traffic_source.liff_id'),
      }),
    }
    return requiredRule
  }

  get modalTitle() {
    return this.$t('label.traffic_source.how_to_initialization')
  }

  onSubmit() {
    this.$refs.formRef
      .validate()
      .then(async (success: boolean) => {
        if (!success) {
          return
        }

        await this.doSave()
      })
      .catch((error: unknown) => {
        console.log('error', error)
      })
  }

  async doSave() {
    const { dispatch } = this.$store
    let success = false
    success = await dispatch(ACTION_APP.UPDATE, {
      _id: this.form._id,
      ...this.form,
    })

    if (success) {
      this.$q.notify({
        message: this.$t('messages.saved'),
        color: 'positive',
      })
    }

    this.visible = false
  }

  getWebhookURL(app_id) {
    const baseUrl = process.env.VUE_APP_API_ENDPOINT
    const url = `${baseUrl}webhook/liff-app?app_id=${app_id}`
    return url
  }

  onCancel() {
    this.visible = false
    this.goto('overview', { app_id: this.form._id })
  }

  goto(name: string, params = {}) {
    this.$router.push({
      name,
      params,
    })
  }

  created() {
    this.form = { ...this.form, ...cloneDeep(this.data) }
  }
}
</script>
