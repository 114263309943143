
import { Options, mixins } from 'vue-class-component'
import { Prop } from 'vue-property-decorator'
import { maska } from 'maska'
import cloneDeep from 'lodash/cloneDeep'
import { IApp } from '@/utils/types'
import { ACTION_APP } from '@/store/actions'
import CLoading from '@/components/common/ui/CLoading.vue'
import BaseFormDialogMixin from '../common/mixins/BaseFormDialogMixin.vue'
import TagContent from '@/components/common/ui/TagContent.vue'

@Options({
  components: { CLoading, TagContent },
  directives: { maska },
  emits: ['update:onSelect'],
})
export default class LIFFSettingFormModal extends mixins(BaseFormDialogMixin) {
  @Prop()
  data!: IApp

  form: IApp = {}

  get requiredRule() {
    const requiredRule = {
      liff_id: this.$t('validate.this_field_is_required', {
        placeholder: this.$t('label.traffic_source.liff_id'),
      }),
    }
    return requiredRule
  }

  get modalTitle() {
    return this.$t('label.traffic_source.liff_setting')
  }

  onSubmit() {
    this.$refs.formRef
      .validate()
      .then(async (success: boolean) => {
        if (!success) {
          return
        }

        await this.doSave()
      })
      .catch((error: unknown) => {
        console.log('error', error)
      })
  }

  async doSave() {
    const { dispatch } = this.$store
    let success = false
    success = await dispatch(ACTION_APP.UPDATE, {
      _id: this.form._id,
      ...this.form,
    })

    if (success) {
      this.$q.notify({
        message: this.$t('messages.saved'),
        color: 'positive',
      })
    }

    this.visible = false
  }

  getWebhookURL(app_id) {
    const baseUrl = process.env.VUE_APP_API_ENDPOINT
    const url = `${baseUrl}webhook/liff-app?app_id=${app_id}`
    return url
  }

  created() {
    this.form = { ...this.form, ...cloneDeep(this.data) }
  }
}
